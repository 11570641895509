import { Heading, Center, Text, VStack } from "@chakra-ui/react"

export default function Tutorial () {
    return(
        <Center>
            <VStack className="mx-5">
                <Heading as="h1">Tutorial</Heading>
                <Heading as="h3" size="lg">How to claim a rock</Heading>
                <Text>Look for the NFC tag somewhere on the rock. Sometimes it is hidden under the paint, other times it obviously marked. When the NFC tag is found, tap your phone to it and follow the link prompt to claim the rock.</Text>
                <Text>Once the rock has been claimed you can do anything you want with it. If you want to remove the NFC tag you should be able to without it damaging the paint (unless it has been painted over). And if you do remove the NFC tag please throw it away properly and don't litter</Text>
                <Heading as="h3" size="lg">How to find a rock</Heading>
                <Text>Select any of the blurred rocks on the home screen, then click the coordinates to see them on a map. Usually the rocks are in plain sight near a path. You shouldn't have to go anywhere that isn't walkable.</Text>
            </VStack>
        </Center>
    )
}