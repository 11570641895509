import { 
    Card,
    CardBody, 
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Center,
    Flex
} from '@chakra-ui/react'
import Rocktag from './Rocktag';
import getImageURL from 'lib/getImageURL';

export default function Rockcard ({name, season, year, series, seriesColors, coordinates, collection_id, found, rock_id, image_src, found_by}) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const image_url = getImageURL({collection_id, rock_id, image_src})
    const image_class = `${found ? 'blur-none' : 'blur'} object-cover w-full h-full`

    return(
        <div className='mx-3'>
            <Card
                className='cursor-pointer'
                onClick={onOpen}
            >
                <CardBody>
                    <div
                        className='aspect-square overflow-hidden'
                    >
                        <Image
                            src={image_url}
                            alt={name}
                            className={image_class}
                        />
                    </div>
                    <Center
                        className='m-2 text-xl -mb-4'
                    >{name}</Center>
                </CardBody>
            </Card>

            <Modal 
                isOpen={isOpen} 
                onClose={onClose}
                size='xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center>
                    <Flex
                        flexWrap="wrap"
                        className='mb-5'
                    >
                        <div className='aspect-square overflow-hidden mx-auto'>
                            <Image
                                src={image_url}
                                alt={name}
                                className={image_class}
                            />
                        </div>
                        <div className='mt-10'>
                            <Rocktag 
                                season={season}
                                year={year} 
                                series={series}
                                seriesColors={seriesColors}
                                coordinates={coordinates}
                                found={found}
                                found_by={found_by}
                                size="lg"
                            />
                        </div>
                    </Flex>
                    </Center>
                </ModalBody>
                </ModalContent>
            </Modal>
        </div>
        )
}