import { Center, Text } from "@chakra-ui/react"

export default function About () {
    return(
        <Center className="mx-10">
            <Text>These rocks were painted with ❤️ for the people of Richmond VA/Oregon Hill. 
            <br /> If you have any questions or concerns you can email them to "carleomitolo at gmail.com" with the subject line mentioning OHRock Hunt or something.
            <br /> Happy Hunting!
            </Text>
        </Center>
    )
}