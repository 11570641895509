import { Center, 
         SimpleGrid,
         Image,
         Card,
         Button,
         Input,
         VStack,
         Heading
    } from '@chakra-ui/react'

import { useState } from 'react';
import pb from 'lib/pocketbase';
import { useLoaderData } from 'react-router-dom'
import Rocktag from '../components/Rocktag';
import getImageURL from 'lib/getImageURL';
import { getRockCollection } from 'lib/getRockCollection';


export default function ClaimRock () {
    const [inputValue, setInputValue] = useState('');
    const data = useLoaderData();
    const rock = data.rock

    const rock_id = rock.id
    const name = rock.name
    const season = rock.season
    const series = rock.series
    const coordinates = rock.coordinates
    const image_src = rock.image
    const found = rock.found
    const collection_id = rock.collectionId
    const found_by = rock.found_by

    const image_class = `object-cover w-full h-full`
    const image_url = getImageURL({collection_id, rock_id, image_src})

    const seriesColors = data.seriesColor;

    const handleInputChange = (event) => {
        setInputValue(event.target.value)
    }

    const handleButtonClick = async (event) => {
        event.preventDefault();
        await updateFoundBy();
        window.location.reload();
    }

    const updateFoundBy = async () => {
        const data = {
            "name": name,
            "coordinates": coordinates,
            "found": true,
            "season": season,
            "series": series,
            "found_by": `${inputValue}`
        };
    
        const record = await pb.collection(getRockCollection()).update(rock_id, data)
        return record
    }
    

    return(
        <Center>
            <Card className='m-5'>
                <SimpleGrid 
                    columns={1} 
                    gap={5} 
                    className='m-5'>
                    <Heading>{name}</Heading>
                    <div className="aspect-square overflow-hidden">
                        <Image 
                            src={image_url}
                            alt={name}
                            className={image_class}
                        />
                    </div>
                    <div>
                        <Rocktag 
                            season={season} 
                            series={series}
                            coordinates={coordinates}
                            found={found}
                            found_by={found_by}
                            seriesColors={seriesColors}
                            size="lg"
                        />
                        {!found ? (
                            <VStack
                                className='mt-10'
                                align="left"
                            >
                                <p>You are the first person to find this rock!</p>
                                <Input
                                    placeholder="Enter your name/username"
                                    onChange={handleInputChange}
                                />
                                <Button onClick={handleButtonClick}>Claim this rock now!</Button>
                            </VStack>
                        ):(
                            <p>This rock has been found already!</p>
                        )}
                    </div>
                </SimpleGrid>
            </Card>
        </Center>
    )
}

// loader function
export const rockDetailsLoader = async ({params}) => {
    const { id } = params
    const rock = await pb.collection(getRockCollection()).getFirstListItem(`id="${id}"`, {});
    const seriesColors = await pb.collection('series_colors').getFullList();
    const data = {
        'rock':rock,
        'seriesColor':seriesColors
    }
    
    return data;
}