import Navbar from "components/Navbar";
import React from "react";
import { Outlet } from "react-router-dom";


export default function RootLayout() {
    return (
        <div className="layout overflow-x-hidden">
            <Navbar />
            <main>
                <Outlet />
            </main>
        </div>
    )
}