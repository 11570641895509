import pb from "lib/pocketbase";
import Rockcard from "../components/Rockcard";
import FilterAndSearch from "../components/FilterAndSearch";
import { useLoaderData } from 'react-router-dom';
import { useEffect, useState } from "react"
import { Flex, Center } from '@chakra-ui/react'
import { getRockCollection } from "lib/getRockCollection";

export default function Main () {
    const data = useLoaderData();
    const [rocks, setRocks] = useState([...data.rocks]);

    const seriesList = [...new Set(data.rocks.map(item => item.series))];
    const seasonsList = [...new Set(data.rocks.map(item => item.season))];
    const yearList = [...new Set(data.rocks.map(item => item.year))];
    const seriesColors = [...data.seriesColors]


    const [filterText, setFilterText] = useState('');
    const [filterSeries, setFilterSeries] = useState('');
    const [filterSeason, setFilterSeason] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterFound, setFilterFound] = useState('');

    useEffect(() => {
        const filterRecords = () => {
            let records = [...data.rocks];
            if(filterText) {
                records = records.filter(item => 
                    item.name.includes(filterText) 
                    || item.season.includes(filterText) 
                    || item.series.includes(filterText)
                    || item.name.toLowerCase().includes(filterText) 
                    || item.season.toLowerCase().includes(filterText) 
                    || item.series.toLowerCase().includes(filterText)
                    )
            }
            if(filterFound) {
                records = records.filter(item => item.found === !filterFound)
            }
            if(filterSeries) {
                records = records.filter(item => item.series === filterSeries)
            }
            if(filterSeason) {
                records = records.filter(item => item.season === filterSeason)
            }
            if(filterYear) {
                records = records.filter(item => item.year === filterYear)
            }

            setRocks(records);
        }

        filterRecords();
    },[filterText,filterSeries,filterSeason,filterYear,filterFound, data.rocks])

    const handleTextChange = (event) => {
        const value = event.target.value;
        setFilterText(value)
    }

    const handleSeriesChange = (event) => {
        const value = event.target.value;
        setFilterSeries(value)
    }

    const handleSeasonChange = (event) => {
        const value = event.target.value;
        setFilterSeason(value)
    }

    const handleYearChange = (event) => {
        const value = event.target.value;
        setFilterYear(value)
    }

    const handleFoundChange = (event) => {
        let value = event.target.checked;
        setFilterFound(value)
    }


    return(
        <div>
            <FilterAndSearch 
                onFilterTextChange={handleTextChange}
                return data
                onFilterSeriesChange={handleSeriesChange}
                onFilterSeasonChange={handleSeasonChange}
                onFilterYearChange={handleYearChange}
                onFilterFoundChange={handleFoundChange}

                seriesList={seriesList}
                seasonList={seasonsList}
                yearList={yearList}
            />

                {rocks.length > 0 && (
                    <Flex
                        flexWrap="wrap"
                        className="p-5"
                    >
                        {rocks.map((item) => (
                            <div 
                                className="max-w-sm m-5 mx-auto"
                                key={item.id}
                            >
                                <Rockcard
                                    name={item.name}
                                    season={item.season}
                                    year={item.year}
                                    series={item.series}
                                    seriesColors={seriesColors}
                                    coordinates={item.coordinates}
                                    collection_id={item.collectionId}
                                    found={item.found}
                                    rock_id={item.id}
                                    found_by={item.found_by}
                                    image_src={item.image}
                                />
                            </div>
                        ))}
                    </Flex>
                )}

                {rocks.length === 0 &&(
                    <Center className="mt-10">
                        <p>No search results :&#40;</p>
                    </Center>
                )}
        </div>
    )
    
}

export const mainPageDetailsLoader = async () => {
    const rocks = await pb.collection(getRockCollection()).getFullList({
        sort: '-created',
    });
    const seriesColors = await pb.collection('series_colors').getFullList();
    const data = {
        'rocks': rocks,
        'seriesColors': seriesColors
    }
    
    return data;
}