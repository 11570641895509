import {
    Tag,
    VStack,
    HStack,
    Link,
} from '@chakra-ui/react'

export default function Rocktag ({series, season, year, found, coordinates, found_by, size, seriesColors}) {
    let series_object = seriesColors.find((obj) => obj.Series_Name === series );
    let season_color = ''
    let coordinates_color= ''
    let coordinates_link = `https://www.google.com/maps/place/${coordinates}`

    // Season Colors
    if(season.includes('dev')) {
        season_color = 'gray';
    } else if(season.includes('Spring')) {
        season_color = 'pink';
    } else if(season.includes('Summer')) {
        season_color = 'yellow';
    } else if(season.includes('Fall')) {
        season_color = 'orange';
    } else if(season.includes('Winter')) {
        season_color = 'blue';
    }

    // Coordinates Color
    if(coordinates.includes('TBD')) {
        coordinates_color = 'yellow'
    } else {
        coordinates_color = 'green'
    }

    return (
        <VStack
            spacing={2}
            align='left'
        >
            <HStack>
                <p className='text-lg font-xl'>Series: </p>
                <p  
                    className={size === 'lg' ? 'px-2 py-1 rounded-md' : 'px-2 rounded-md'}
                    style={{backgroundColor: `${series_object.Background}`, color: `${series_object.Text}`}}
                >
                {series}
                </p>
            </HStack>

            <HStack>
                <p className='text-lg font-xl'>Season: </p>
                <Tag
                    colorScheme={season_color}
                    size={size}
                >
                {season} {year}
                </Tag>
            </HStack>
          
            <HStack>
                <p className='text-lg font-xl'>Found: </p>
                <Tag
                    colorScheme={found ? 'green' : 'red'}
                    size={size}
                >
                {found ? 'Yes' : 'No'}
                </Tag>
            </HStack>
            
            {found &&             
                <HStack>
                    <p className='text-lg font-xl'>Found by: </p>
                    <Tag
                        colorScheme='green'
                        size={size}
                    >
                    {found_by}
                    </Tag>
                </HStack>
            }

            {!found &&             
                <HStack>
                    <p className='text-lg font-xl'>Coordinates: </p>
                    {coordinates === 'TBD' &&                     
                        <Tag
                            colorScheme={coordinates_color}
                            size={size}
                        >
                        {coordinates}
                        </Tag>
                    }
                    {coordinates !== 'TBD' &&
                        <Link href={coordinates_link} isExternal>
                            <Tag
                                colorScheme={coordinates_color}
                                size={size}
                            >
                            {coordinates}
                            </Tag>
                        </Link>
                    }
                </HStack>
            }
        </VStack>
    )
}