import { 
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
    Input,
    AccordionIcon,
    Select,
    InputGroup,
    InputLeftElement,
    Checkbox,
    VStack,
    HStack
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

export default function FilterAndSearch({
    onFilterTextChange, 
    onFilterSeriesChange, 
    onFilterSeasonChange, 
    onFilterYearChange, 
    onFilterFoundChange, 
    seriesList, 
    seasonList, 
    yearList
}) {

    return(
        <Accordion allowToggle>
        <AccordionItem>
            <h2>
            <AccordionButton>
                <Box as="span" flex='1' p={3} textAlign='left' className="-mx-5">
                <p className="text-2xl ">Filter and Search</p>
                </Box>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel>
                <VStack
                    alignItems="left"
                >

                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <Search2Icon color='gray.300' />
                    </InputLeftElement>
                <Input 
                    type='search' 
                    placeholder='"A rock name or season or somethin"'
                    onChange={onFilterTextChange}
                    />
                </InputGroup>

                <HStack>
                    <Select 
                        placeholder='Series'
                        onChange={onFilterSeriesChange}
                        >
                        {seriesList.map((item) => (
                            <option 
                                key={item}    
                                value={item}
                            >{item}</option>
                        ))}
                    </Select>
                </HStack>

                <HStack>
                    <Select 
                        placeholder="Season"
                        onChange={onFilterSeasonChange}
                    >
                        {seasonList.map((item) => 
                            <option
                                key={item}
                                value={item}
                            >{item}</option>
                        )}
                    </Select>
                    <Select 
                        placeholder="Year"
                        onChange={onFilterYearChange}
                    >
                        {yearList.map((item) => 
                            <option
                                key={item}
                                value={item}
                            >{item}</option>
                        )}
                    </Select>
                </HStack>

                <HStack>
                    <Checkbox
                        onChange={onFilterFoundChange}
                    >Hide Rocks already found</Checkbox>
                </HStack>

                </VStack>
            </AccordionPanel>
        </AccordionItem>
        </Accordion>
    )
}