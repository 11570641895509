import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Navbar() {
    const linkNames = ['Tutorial', 'Updates', 'About']
    return(
            <div className="flex flex-row p-2 flex-wrap content-center">
                <NavLink 
                    to="/"
                    className="flex-col"
                >
                    <h1 className="text-5xl">Oregon Hill</h1>
                    <h2 className="text-4xl">Rock Hunt</h2>
                </NavLink>
                <div className='mx-auto flex flex-row mt-3'>
                    {linkNames.map((name) => {
                        let route = `/${name}`
                        return(
                            <NavLink
                                key={name}
                                to={route}
                                className="mx-5"
                                >
                                <p className='text-xl'>{name}</p>
                            </NavLink>
                        )
                    })}
                </div>
            </div>
    )
}