import { 
    Text, 
    Heading,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    VStack
} from "@chakra-ui/react"
import { useLoaderData } from 'react-router-dom'
import pb from 'lib/pocketbase';

export default function Updates () {
    const updates = useLoaderData();
    return(
        <div>
            {updates.length > 0 && (
                <VStack className="p-10">
                    {updates.map((update) => (
                        <Card key={update.id} className="mt-10 w-full">
                            <CardHeader>
                                <Heading>
                                    {update.header}
                                </Heading>
                                <Text className="ml-0">
                                    {update.date.substring(0,10)}
                                </Text>
                            </CardHeader>
                            <CardBody dangerouslySetInnerHTML={{__html: update.post}}>
                            </CardBody>
                            <CardFooter className="ml-auto">
                                - leo
                            </CardFooter>
                        </Card>
                    ))}
                </VStack>
            )}
        </div>
    )
}

export const updatesDetailsLoader = async () => {
    const updates = await pb.collection('updates').getFullList({
        sort: '-created',
    });
    return updates
}