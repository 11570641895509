import Main, { mainPageDetailsLoader } from "pages/Main";
import ClaimRock, { rockDetailsLoader } from "pages/ClaimRock";
import {
  Route, 
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";

// Layouts
import RootLayout from "layouts/RootLayout";
import Tutorial from "pages/Tutorial";
import Updates, { updatesDetailsLoader } from "pages/Updates";
import About from "pages/About";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route 
        path="/" 
        element={<Main />}
        loader={mainPageDetailsLoader}
      >
      </Route>
      <Route 
        path="/claim_rock/:id" 
        element={<ClaimRock />} 
        loader={rockDetailsLoader}
      ></Route>
      <Route
        path="/tutorial"
        element={<Tutorial />}
      ></Route>
      <Route
        path="/updates"
        element={<Updates />}
        loader={updatesDetailsLoader}
      ></Route>
      <Route
        path="/about"
        element={<About />}
      ></Route>
    </Route>
  )
)



function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;